import React, {useEffect, useState} from "react";
import {Form, Input, Button, Space, Modal, message, InputNumber, Switch} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import TextArea from "antd/es/input/TextArea";
import Api from "../api";
import {useParams} from "react-router-dom";

const NewBingoCategory = ({setCategories, categories, loadCategories}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const params = useParams();

    const showModal = () => {
        setIsModalVisible(true);
    };

    useEffect(() => {

    }, [])

    const onFinish = (values: any) => {
        Api.post(`/admin/v2/bingos/${params.id}/categories`, values).then(res => {
            setIsModalVisible(false);
            loadCategories()
            return message.success('Success! New category added!');
        }).catch(e => {
            return message.error(`${e.message}`);
        })
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Button className="add-translation-btn" type="primary" onClick={showModal}>
                <PlusOutlined/> Add new category
            </Button>

            <Modal title="Add new category"
                   visible={isModalVisible}
                   onCancel={handleCancel}

                   footer={null}
            >
                <Form
                    name="add-bingo-category"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={onFinish}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Please insert category name' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>

    );
};

export default NewBingoCategory;