import React, {useEffect, useState} from "react";
import {
    Layout,
    Breadcrumb,
    Row,
    Col,
    Table, Tag, Button, Descriptions, Image, Divider, Typography
} from 'antd';
import Api from "../api.js";
import {Link, useParams} from "react-router-dom"
import CreateCompetition from "../components/create-competition";
import moment from "moment/moment";
import EditTrainingVideoCategories from "../components/edit-training-video-categories";

const {Content} = Layout;
const {Title} = Typography;

const CompetitionView = () => {
    const params = useParams();
    const [competition, setCompetition] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        getCompetitions();
    }, [])

    const getCompetitions = () => {
        setLoading(true);

        Api.get('/admin/competitions/' + params.id + '/participants').then(res => {
            setCompetition(res.data);

            console.log(res.data);

            setLoading(false);
        })
    }

    const removeParticipant = (id) => {
        Api.delete('/admin/competitions/' + params.id + '/participants/' + id).then(res => {
            getCompetitions()
        })
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 60,
        },
        {
            title: 'user_id',
            dataIndex: 'participatable_id',
            key: 'participatable_id',
            width: 100,
            render: (value, record) => (<Link to={"/user/" + value}>{value}</Link>)
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
            render: (value, record) => (record.user ? record.user.email : '')
        },
        competition.type === 'steps' && {
            title: 'Steps count',
            dataIndex: 'total_steps',
            key: 'total_steps',
            render: (value, record) => (record.user ? record.user.steps_count : '')
        },
        {
            title: 'action',
            dataIndex: 'action',
            key: 'action',
            render: (value, record) => <Button size="small" onClick={() => removeParticipant(record.id)}
                                               danger={true}>Remove</Button>
        },
    ].filter(Boolean)

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Services</Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/competitions">Competitions</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{params.id}</Breadcrumb.Item>
            </Breadcrumb>


            <Col span={24}>
                <div className="site-layout-card">

                    <Row type="flex" justify="space-between" style={{marginBottom: 20}}>
                        <h1 style={{margin: 0}}>{competition.name}</h1>
                    </Row>

                    <Row>
                        <Descriptions bordered column={2}>
                            <Descriptions.Item label="Image"><Image src={competition.image}
                                                                    width={300}/></Descriptions.Item>
                            <Descriptions.Item label="Name">{competition.name}</Descriptions.Item>
                            <Descriptions.Item label="Type">{competition.type === 'bingo' ? <Link
                                to={"/bingos/" + competition.bingo_id}>{competition.type} #{competition.bingo_id}</Link> : competition.type}</Descriptions.Item>
                            <Descriptions.Item label="Summary">{competition.summary}</Descriptions.Item>
                            <Descriptions.Item label="Start Date">{competition.begin_date}</Descriptions.Item>
                            <Descriptions.Item label="End Date">{competition.end_date}</Descriptions.Item>
                            <Descriptions.Item label="Status">
                                {competition.enabled ? <Tag color="green">Enabled</Tag> :
                                    <Tag color="red">Disabled</Tag>}
                            </Descriptions.Item>
                            <Descriptions.Item label="Paid">
                                {competition.is_paid ? <Tag color="gold">Paid</Tag> : <Tag color="gray">Free</Tag>}
                            </Descriptions.Item>
                        </Descriptions>
                    </Row>
                    <br/>
                    <Divider orientation="left">
                        <Title style={{margin: 0}} level={4}>Participants</Title>
                    </Divider>

                    <Row>
                        <Col span={24}>
                            <Table loading={loading} style={{width: "100%"}} pagination={false} columns={columns}
                                   dataSource={competition.sorted_participants}/>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Content>
    );
}

export default CompetitionView;