import React, {useEffect, useState} from "react";
import {
    Layout,
    Breadcrumb,
    Row,
    Col,
    Table, Tag, Button, Image, Form, message, Modal, Input, Select, Checkbox, Switch, Space
} from 'antd';
import Api from "../api.js";
import {Link, useParams} from "react-router-dom"
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";

const {Content} = Layout;
const {TextArea} = Input;

const Bingos = () => {
    const params = useParams();
    const [bingos, setBingos] = useState([]);
    const [editBingo, setEditBingo] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        getBingos();
    }, [])

    const getBingos = () => {
        setLoading(true);

        Api.get('/admin/v2/bingos').then(res => {
            setBingos(res.data);
            setLoading(false);
        })
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 60,
        },
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <Link to={"/bingos/" + record.id}>{text}</Link>,
        },
        {
            title: 'description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'competition',
            dataIndex: 'competition',
            key: 'competition',
            render: (text, record) => record.competition ? <Link to={"/competitions/" + record.competition.id}>{record.competition.name}</Link> : "",
        },
        {
            title: 'actions',
            dataIndex: 'actions',
            key: 'actions',
            width: 200,
            render: (text, record) => <Space>
                <Button icon={<EditOutlined/>} onClick={() => alert('todo edit')}></Button>
                <Button icon={<DeleteOutlined/>} onClick={() => alert('todo delete')} danger></Button>
            </Space>,
        },
    ]

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Services</Breadcrumb.Item>
                <Breadcrumb.Item>Bingos</Breadcrumb.Item>
            </Breadcrumb>

            <Col span={24}>
                <div className="site-layout-card">

                    <Row type="flex" justify="space-between" style={{marginBottom: 20}}>
                        <h1 style={{margin: 0}}>Bingos</h1>

                        <CreateBingo callback={getBingos} bingoData={editBingo} setBingoData={setEditBingo}/>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Table loading={loading} size="small" style={{width: "100%"}} pagination={false} columns={columns}
                                   dataSource={bingos}/>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Content>
    );
}

const CreateBingo = ({callback, bingoData, setBingoData}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [bingos, setBingos] = useState([]);

    useEffect(() => {
        if (bingoData) {
            setIsModalOpen(true);

            form.setFieldsValue({
                name: bingoData.name,
                description: bingoData.description
            });
        } else {
            form.setFieldsValue({
                name: '',
                description: "",
            });
        }
    }, [bingoData, form]);

    const handleCancel = () => {
        setBingoData(null); // Reset bingoData (null to close the modal)
        setIsModalOpen(false);
        form.resetFields(); // Reset form fields when modal closes
    };

    const handleSubmit = (values) => {
        const {name, description} = values;

        let competitionPayload = {
            name,
            description,
        };

        // If bingoData is provided, update the competition
        const request = bingoData
            ? Api.put(`/admin/v2/bingos/${bingoData.id}`, competitionPayload) // Update
            : Api.post("/admin/v2/bingos", competitionPayload); // Create

        request
            .then((response) => {
                callback();
                message.success(bingoData ? "Bingo updated successfully!" : "Bingo created successfully!");
                form.resetFields();
                setIsModalOpen(false);
            })
            .catch((error) => {
                message.error("Failed to process competition. Please try again.");
            });
    };

    return (
        <div>
            <Button type="primary" onClick={() => setIsModalOpen(true)}>
                Create Bingo
            </Button>

            <Modal
                destroyOnClose={true}
                title={<div
                    style={{textAlign: "center"}}>{bingoData ? "Edit Bingo" : "Create New Bingo"}</div>}
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null} // Footer is handled by form buttons
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Form.Item
                        name="name"
                        label="Bingo Name"
                        rules={[{required: true, message: "Please enter the competition name"}]}
                    >
                        <Input placeholder="Enter bingo name"/>
                    </Form.Item>

                    <Form.Item
                        name="description"
                        label="Description"
                    >
                        <TextArea placeholder="Description"/>
                    </Form.Item>

                    <Form.Item>
                        <Row type="flex" justify="center">
                            <Button type="primary" htmlType="submit" style={{marginRight: "10px"}}>
                                {bingoData ? "Update" : "Submit"}
                            </Button>
                            <Button onClick={handleCancel}>Cancel</Button>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};


export default Bingos;