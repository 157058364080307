import React, {useEffect, useState} from "react";
import {
    Layout,
    Breadcrumb,
    Row,
    Col,
    Table, Tag, Button, Image, Tabs
} from 'antd';
import Api from "../api.js";
import {Link, useParams} from "react-router-dom"
import CreateCompetition from "../components/create-competition";
import {EditOutlined} from "@ant-design/icons";
import dayjs from 'dayjs';

const {Content} = Layout;
const {TabPane} = Tabs;

const Competitions = () => {
    const params = useParams();
    const [competitions, setCompetitions] = useState([]);
    const [competitionData, setCompetitionData] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        getCompetitions();
    }, [])

    const getCompetitions = () => {
        setLoading(true);

        Api.get('/admin/v2/competitions').then(res => {
            setCompetitions(res.data);
            setLoading(false);
        })
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 60,
        },
        {
            title: 'image',
            dataIndex: 'image',
            key: 'image',
            width: 100,
            render: (text, record) => <Image style={{maxWidth: "100px"}} src={text} alt=""/>
        },
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <Link to={"/competitions/" + record.id}>{text}</Link>,
        },
        {
            title: 'Participants',
            dataIndex: 'participants_count',
            key: 'participants_count',
            width: 50
        },
        {
            title: 'begin_date',
            dataIndex: 'begin_date',
            key: 'begin_date',
            width: 140,
            render: (text) => <span>{dayjs(text).format('YYYY-MM-DD')}</span> // Customize the format as needed
        },
        {
            title: 'end_date',
            dataIndex: 'end_date',
            key: 'end_date',
            width: 140,
            render: (text) => <span>{dayjs(text).format('YYYY-MM-DD')}</span> // Customize the format as needed
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: 120,
            render: (type, record) => record.type === 'bingo' ? (
                <Link
                    to={"/bingos/" + record.bingo_id}>{type} #{record.bingo_id}</Link>
            ) : type
        },
        {
            title: 'Is paid',
            dataIndex: 'is_paid',
            key: 'is_paid',
            render: (isPaid, record) => <span>
                {isPaid ? (
                    <div>
                        <Tag color="gold">Paid</Tag>
                    </div>
                ) : (
                    <Tag color="green">Free</Tag>
                )}
            </span>,
            width: 60,
        },
        {
            title: 'Status',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 100,
            render: text => <span><Tag
                color={text ? "green" : "red"}>{text ? "active" : "inactive"}</Tag></span>
        },
        {
            title: 'action',
            dataIndex: 'action',
            key: 'action',
            width: 100,
            render: (text, record) => <Button onClick={() => setCompetitionData(record)}><EditOutlined/></Button>
        },
    ]

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Services</Breadcrumb.Item>
                <Breadcrumb.Item>Competitions</Breadcrumb.Item>
            </Breadcrumb>

            <Col span={24}>
                <div className="site-layout-card">

                    <Row type="flex" justify="space-between" style={{marginBottom: 20}}>
                        <h1 style={{margin: 0}}>Competitions</h1>

                        <CreateCompetition competitionData={competitionData} setCompetitionData={setCompetitionData} callback={getCompetitions}/>
                    </Row>

                    <Tabs defaultActiveKey="videos">
                        <TabPane tab="Steps" key="steps">
                            <Table
                                size="small"
                                dataSource={competitions.filter(comp => comp.type === "steps")}
                                columns={columns}
                                rowKey="id"
                                bordered
                                pagination={false}
                            />
                        </TabPane>
                        <TabPane tab="Bingo" key="bingo">
                            <Table
                                size="small"
                                dataSource={competitions.filter(comp => comp.type === "bingo")}
                                columns={columns}
                                rowKey="id"
                                bordered
                                pagination={false}
                            />
                        </TabPane>

                        <TabPane tab="Weight" key="weight" disabled={true}></TabPane>
                    </Tabs>
                </div>
            </Col>
        </Content>
    );
}

export default Competitions;