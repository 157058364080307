import React, {createContext, useContext, useState, useEffect} from 'react';
import './App.scss';
import {
    Layout,
    Menu,
    Button,
    Result,
    Spin,
    Avatar,
    Dropdown,
    Select,
    Badge,
    ConfigProvider,
    theme,
    Space,
    Row, Tag
} from 'antd';
import Icon, {
    TranslationOutlined,
    HomeOutlined,
    CommentOutlined,
    UserOutlined,
    UserAddOutlined,
    StarOutlined,
    FireOutlined,
    LoadingOutlined,
    DotChartOutlined,
    LinkOutlined,
    CrownOutlined,
    BarcodeOutlined,
    BookOutlined,
    GiftOutlined,
    VideoCameraOutlined,
    NotificationOutlined,
    CheckOutlined,
    CalendarOutlined,
    MoonOutlined,
    DollarCircleOutlined,
    WarningOutlined,
    BugOutlined,
    SmileOutlined,
    LineChartOutlined,
    AreaChartOutlined,
    TrophyOutlined,
    CheckCircleOutlined,
    CustomerServiceFilled,
    MoneyCollectFilled,
    CameraOutlined,
    PlayCircleOutlined,
    PlusCircleOutlined,
    InteractionOutlined, BarChartOutlined
} from '@ant-design/icons';
import {Routes, Route, Link} from 'react-router-dom';
import Comments from "./pages/comments";
import Dashboard from "./pages/dashboard";
import Translations from "./pages/translations";
import TranslationView from "./pages/translation-view";
import Api from "./api.js";
import Recipes from "./pages/recipes";
import Barcode from "./pages/barcode";
import RecipeView from "./pages/recipe-view";
import User from "./pages/user.js";
import Users from "./pages/users.js";
import {config, getCookie, redirect, removeCookie, setCookie} from "./helpers";
import Settings from "./pages/settings.js";
import Ingredients from "./pages/ingredients";
import IngredientView from "./pages/ingredient-view";
import Selver from "./pages/selver";
import Levels from "./pages/levels";
import Actions from "./pages/actions";
import AdventCalendar from "./pages/advent-calendar";
import Tasks from "./pages/tasks";
import ShortUrls from "./pages/short-urls";
import AdventView from "./pages/advent-view";
import TaskView from "./pages/task-view";
import DefaultSurveyQuestions from "./pages/default-survey-questions";
import CreateRecipeView from "./pages/create-recipe-view";
import Reports from "./pages/reports";
import TrainingVideosView from "./pages/training-videos-view";
import FreewareIngredientsView from "./pages/freeware-ingredients-view";
import SubstituteGroupsView from "./pages/substitute-groups-view";
import SubstituteGroupView from "./pages/substitute-group-view";
import AllergensView from "./pages/ingredients/allergens-view";
import AllergenView from "./pages/ingredients/allergen-view";
import CustomUnitsView from "./pages/ingredients/custom-units-view";
import CustomUnitView from "./pages/ingredients/custom-unit-view";
import MenuTemplatesView from "./pages/menu-templates-view";
import MenuTemplateView from "./pages/menu-template-view";
import TrainingVideosCategoryView from "./pages/training-videos-category-view";
import NotificationsView from "./pages/notifications-view";
import NotificationView from "./pages/notification-view";
import TrainingVideoView from "./pages/training-video-view";
import BingoChallengesView from "./pages/bingo-challenges-view";
import Bingos from "./pages/bingos";
import BingoChallengeCategoryView from "./pages/bingo-challenge-category-view";
import BingoChallengeView from "./pages/bingo-challenge-view";
import Exercises from "./pages/exercises";
import {HappyProvider} from '@ant-design/happy-work-theme';
import ScrollToTop from "./ScrollToTop";
import Login from "./pages/login";
import Invoices from "./pages/invoices";
import ErrorLog from "./pages/error-log";
import Wellness from "./pages/wellness";
import Competitions from "./pages/competitions.js";
import AppVisitedReport from "./pages/app-visited-report.js";
import ActiveInactiveReport from "./pages/active-inactive-report.js";
import CompetitionView from "./pages/competition-view";
import CoursesView from "./pages/courses-view";

const {Header, Footer, Sider} = Layout;
const {SubMenu} = Menu;
const {Option} = Select;

const ThemeContext = createContext();
export const useTheme = () => useContext(ThemeContext);

const App = ({}) => {
    const [collapsed, setCollapsed] = useState('');
    const [hasAccess, setHasAccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(!getCookie('white_mode'));

    const toggleTheme = () => {
        if (isDarkMode) {
            setCookie('white_mode', true, 365);
        } else {
            removeCookie('white_mode');
        }

        setIsDarkMode(!isDarkMode);
    };

    useEffect(() => {
        Api.auth().then(res => {
            setUser(res.data.data);

            setTimeout(() => {setLoading(false)}, 1500)

            if (process.env.REACT_APP_WHITELIST.includes(res.data.data.email)) {
                setHasAccess(true);
            }
        }).catch(err => {
            setTimeout(() => {setLoading(false)}, 1500)
        })
    }, [])

    const onCollapse = collapsed => {
        setCollapsed(collapsed);
    };

    if (loading) {
        return (
            <div>
                <Layout style={{minHeight: '100vh', backgroundColor: '#141414', textAlign: "center"}}>
                    <Row style={{height: "100vh"}} type="flex" justify="center" align="middle">
                        <Space direction="vertical">
                            <img src="/logo-white.png" alt="" style={{width: 120}} className="logo-sm"/>
                            <Spin className="loading" indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>
                        </Space>
                    </Row>
                </Layout>
            </div>
        )
    }

    if (!hasAccess) {
        return (
            <ThemeContext.Provider value={{isDarkMode, toggleTheme}}>
                <ConfigProvider
                    locale={'en'}
                    theme={{
                        algorithm: theme.darkAlgorithm,
                        token: {
                            fontSize: 14,
                            colorBgLayout: "white",
                            fontFamily: "Nunito, sans-serif",
                            borderRadiusLG: 12,
                            fontSizeLG: 14,
                            boxShadowTertiary: "0 4px 15px #eaeff1",
                            colorBgContainer: "#141414",
                        },
                        components: {
                            Layout: {
                                triggerBg: "#141414"
                            },
                            Header: {
                                triggerBg: "#141414"
                            },
                        },
                    }}
                >
                    <Layout style={{minHeight: '100vh', backgroundColor: '#141414'}}>
                        <Routes>
                            <Route path="*" element={<Login user={user} setUser={setUser} setHasAccess={setHasAccess}/>}/>
                        </Routes>
                    </Layout>
                </ConfigProvider>
            </ThemeContext.Provider>
        )
    }

    const logout = () => {
        removeCookie('fl_admin_access_token');
        removeCookie('fl_admin_refresh_token');
        redirect("https://admin.fitlap.ee")
    }

    const menu = (
        <Menu>
            <Menu.Item>
                <a href="https://app.fitlap.ee">Fitlap</a>
            </Menu.Item>

            <Menu.Item>
                <Link to={"/settings"}>
                    Settings
                </Link>
            </Menu.Item>

            <Menu.Item onClick={logout}>Log out</Menu.Item>
        </Menu>
    );

    const changeSite = (value) => {
        setCookie('api_url', value, 365);
        window.location.reload();
    }

    return (
        <ThemeContext.Provider value={{isDarkMode, toggleTheme}}>
            <ConfigProvider
                locale={'en'}
                theme={{
                    algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
                    token: {
                        fontSize: 14,
                        colorBgLayout: "white",
                        fontFamily: "Nunito, sans-serif",
                        borderRadiusLG: 12,
                        fontSizeLG: 14,
                        boxShadowTertiary: "0 4px 15px #eaeff1",
                        colorBgContainer: isDarkMode ? "#141414" : "white",
                    },
                    components: {
                        Layout: {
                            triggerBg: isDarkMode ? "#141414" : "white"
                        },
                        Header: {
                            triggerBg: isDarkMode ? "#141414" : "white"
                        },
                    },
                }}
            >
                <Header style={{backgroundColor: '#141414'}} className="header">
                    <a href="/">
                        <img src="/logo-white.png" alt="" className="logo-sm"/>
                    </a>

                    <div className="user-nav">
                        <Space>
                            <HappyProvider>
                            <Button onClick={toggleTheme} size="large" style={{borderRadius: 100, width: 40}}><Icon
                                component={MoonOutlined}/></Button>
                                </HappyProvider>
                            <Dropdown overlay={menu}>
                                <Button size="large" style={{borderRadius: 100, width: 40}}><Icon
                                    component={UserOutlined}/></Button>
                            </Dropdown>
                        </Space>
                    </div>
                </Header>

                <HappyProvider>
                    <Layout className={isDarkMode ? "darkmode" : "lightmode"} style={{minHeight: '100vh', backgroundColor: isDarkMode ? '#141414' : '#ffffff'}}>
                        <Sider
                            width={220}
                            style={{  background: isDarkMode ? '#141414' : "#ffffff" }}
                            collapsible theme={isDarkMode ? "dark" : "light"} collapsed={collapsed}
                            onCollapse={onCollapse}>
                            <Menu defaultSelectedKeys={[window.location.pathname]} mode="inline">
                                <Menu.Item className="site-select" key="site">
                                    <Select onChange={changeSite}
                                            defaultValue={getCookie('api_url') ? getCookie('api_url') : config('api.url')}
                                            style={{width: "100%"}}>
                                        <Option value="https://api.fitlap.ee/">Fitlap.ee <Badge style={{fontSize: 10}}
                                                                                                count={'LIVE'}/></Option>
                                        <Option value="https://api.fitlap.com/">Fitlap.com <Badge style={{fontSize: 10}}
                                                                                                  count={'LIVE'}/></Option>
                                        <Option value="https://api2.fitlap.fi/">Fitlap.fi <Badge style={{fontSize: 10}}
                                                                                                 count={'LIVE'}/></Option>
                                        <Option value="https://api.fitlap.in/">Fitlap.in</Option>
                                        <Option value="http://127.0.0.1:8000/">Localhost</Option>
                                    </Select>
                                </Menu.Item>
                                <Menu.Item key="/" icon={<HomeOutlined/>}>
                                    <Link to={"/"}>
                                        Dashboard
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="/users" icon={<UserAddOutlined/>}>
                                    <Link to={"/users"}>
                                        Users
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="/invoices" icon={<DollarCircleOutlined/>}>
                                    <Link to={"/invoices"}>
                                        Invoices
                                    </Link>
                                </Menu.Item>
                                <SubMenu key="/recipes-menu" icon={<FireOutlined/>} title="Recipes">
                                    <Menu.Item key="/recipes">
                                        <Link to={"/recipes"}>
                                            All recipes
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/create-recipe">
                                        <Link to={"/create-recipe"}>
                                            Create recipe
                                        </Link></Menu.Item>
                                </SubMenu>
                                <SubMenu key="ingredients" icon={<BarcodeOutlined/>} title="Ingredients">
                                    <Menu.Item key="/ingredients">
                                        <Link to={"/ingredients"}>
                                            All ingredients
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/ingredients/freeware">
                                        <Link to={"/ingredients/freeware"}>
                                            Freeware
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/ingredients/substitute-groups">
                                        <Link to={"/ingredients/substitute-groups"}>
                                            Substitute Groups
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/ingredients/allergens">
                                        <Link to={"/ingredients/allergens"}>
                                            Allergens
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/ingredients/custom-units">
                                        <Link to={"/ingredients/custom-units"}>
                                            Custom Units
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/selver">
                                        <Link to={"/selver"}>
                                            Selver
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>
                                <SubMenu key="meal-plan" icon={<BookOutlined/>} title="Meal Plan">
                                    <Menu.Item key="/menu-templates">
                                        <Link to={"/menu-templates"}>
                                            Menu Templates
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>
                                <SubMenu key="tasks" icon={<CrownOutlined/>} title="Levels">
                                    <Menu.Item key="15">
                                        <Link to={"/levels"}>
                                            Levels
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="16"><Link to={"/actions"}>
                                        Actions
                                    </Link></Menu.Item>
                                </SubMenu>
                                <SubMenu key="notifications" icon={<NotificationOutlined/>} title="Notifications">
                                    <Menu.Item key="/notifications">
                                        <Link to={"/notifications"}>
                                            Notifications
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/notifications/create">
                                        <Link to={"/notifications/create"}>
                                            New Notification
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>

                                <SubMenu key="services" icon={<PlusCircleOutlined/>} title={<div>Services <Tag color="green">New</Tag></div>}>
                                    <Menu.Item key="/competitions" icon={<TrophyOutlined/>}>
                                        <Link to={"/competitions"}>
                                            Competitions
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key="/bingos" icon={<StarOutlined/>}>
                                        <Link to={"/bingos"}>
                                            Bingos
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key="/courses" icon={<PlayCircleOutlined/>}>
                                        <Link to={"/courses"}>
                                            Courses & podcast
                                        </Link>
                                    </Menu.Item>

                                </SubMenu>

                                <SubMenu key="training" icon={<BookOutlined/>} title="Training">
                                    <Menu.Item key="/training-videos" icon={<VideoCameraOutlined/>}>
                                        <Link to={"/training-videos"}>
                                            Training videos
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/exercises" icon={<CalendarOutlined/>}>
                                        <Link to={"/exercises"}>
                                            Exercises
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>

                                <SubMenu key="reports" icon={<BarChartOutlined/>} title="Reports">
                                    <Menu.Item key="/comments" icon={<CommentOutlined/>}>
                                        <Link to={"/comments"}>
                                            Comments
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key="/tasks" icon={<CheckCircleOutlined/>}>
                                        <Link to={"/tasks"}>
                                            Daily tasks
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/short-url" icon={<LinkOutlined/>}>
                                        <Link to={"/short-url"}>
                                            Short URLs
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key="/app-visited" icon={<LineChartOutlined/>}>
                                        <Link to={"/app-visited"}>
                                            App visited
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/active-inactive" icon={<AreaChartOutlined/>}>
                                        <Link to={"/active-inactive"}>
                                            Active/Inactive customers
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/wellness" icon={<SmileOutlined />}>
                                        <Link to={"/wellness"}>
                                            Wellness
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>

                                <SubMenu key="events" icon={<StarOutlined/>} title="Special events">
                                    <Menu.Item key="/advent-calendar" icon={<GiftOutlined/>}>
                                        <Link to={"/advent-calendar"}>
                                            Advent Calendar
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>

                                <Menu.Item key="/translations" icon={<TranslationOutlined/>}>
                                    <Link to={"/translations"}>
                                        Translations
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key="/error-log" icon={<BugOutlined />}>
                                    <Link to={"/error-log"}>
                                        Error Log
                                    </Link>
                                </Menu.Item>
                            </Menu>
                        </Sider>


                        <ScrollToTop>
                            <Routes>
                                <Route path="/" element={<Dashboard user={user}/>}/>
                                <Route path="/comments" element={<Comments/>}/>
                                <Route exact path="/translations" element={<Translations/>}/>
                                <Route exact path="/translations/:id" element={<TranslationView/>}/>
                                <Route exact path="/recipes" element={<Recipes/>}/>
                                <Route exact path="/recipe/:id" element={<RecipeView key={'edit'}/>}/>
                                <Route exact path="/create-recipe" element={<RecipeView key={'create'}/>}/>
                                <Route exact path="/users" element={<Users/>}/>
                                <Route exact path="/user/:id" element={<User/>}/>
                                <Route exact path="/barcode" element={<Barcode/>}/>
                                <Route exact path="/levels" element={<Levels/>}/>
                                <Route exact path="/actions" element={<Actions/>}/>
                                <Route exact path="/ingredients" element={<Ingredients/>}/>
                                <Route exact path="/ingredients/freeware" element={<FreewareIngredientsView/>}/>
                                <Route exact path="/ingredients/substitute-groups" element={<SubstituteGroupsView/>}/>
                                <Route exact path="/ingredients/substitute-groups/:id" element={<SubstituteGroupView/>}/>
                                <Route exact path="/ingredients/allergens" element={<AllergensView/>}/>
                                <Route exact path="/ingredients/allergens/:id" element={<AllergenView/>}/>
                                <Route exact path="/ingredients/custom-units" element={<CustomUnitsView/>}/>
                                <Route exact path="/ingredients/custom-units/:id" element={<CustomUnitView/>}/>
                                <Route exact path="/ingredient/:id" element={<IngredientView/>}/>
                                <Route exact path="/ingredient/:id" element={<IngredientView/>}/>
                                <Route exact path="/selver" element={<Selver/>}/>
                                <Route exact path="/tasks" element={<Tasks/>}/>
                                <Route exact path="/task/:id" element={<TaskView/>}/>
                                <Route exact path="/settings" element={<Settings user={user}/>}/>
                                <Route exact path="/short-url" element={<ShortUrls/>}/>
                                <Route exact path="/advent-calendar" element={<AdventCalendar/>}/>
                                <Route exact path="/advent-calendar/:id" element={<AdventView/>}/>
                                <Route exact path="/survey/questions" element={<DefaultSurveyQuestions/>}/>
                                <Route exact path="/reports" element={<Reports/>}/>
                                <Route exact path="/bingos" element={<Bingos/>}/>
                                <Route exact path="/bingos/:id" element={<BingoChallengesView/>}/>
                                <Route exact path="/bingo/challenges/:id" element={<BingoChallengeView/>}/>
                                <Route exact path="/bingo/categories/:id" element={<BingoChallengeCategoryView/>}/>
                                <Route exact path="/training-videos" element={<TrainingVideosView/>}/>
                                <Route exact path="/training-videos/:id" element={<TrainingVideoView/>}/>
                                <Route exact path="/training-videos/categories/:id"
                                       element={<TrainingVideosCategoryView/>}/>
                                <Route exact path="/menu-templates" element={<MenuTemplatesView/>}/>
                                <Route exact path="/menu-templates/:id" element={<MenuTemplateView/>}/>
                                {/*Notifications*/}
                                <Route exact path="/notifications" element={<NotificationsView/>}/>
                                <Route exact path="/notifications/create" element={<NotificationView/>}/>
                                <Route exact path="/notifications/:id" element={<NotificationView/>}/>

                                <Route exact path="/invoices" element={<Invoices/>}/>
                                <Route exact path="/wellness" element={<Wellness/>}/>
                                <Route exact path="/competitions" element={<Competitions/>}/>
                                <Route exact path="/competitions/:id" element={<CompetitionView/>}/>
                                <Route exact path="/app-visited" element={<AppVisitedReport/>}/>
                                <Route exact path="/active-inactive" element={<ActiveInactiveReport/>}/>

                                <Route path="/exercises" element={<Exercises/>}/>
                                <Route path="/courses" element={<CoursesView/>}/>
                                <Route path="/error-log" element={<ErrorLog/>}/>
                            </Routes>
                        </ScrollToTop>
                    </Layout>
                </HappyProvider>

                <Footer style={{
                    textAlign: 'center',
                    backgroundColor: isDarkMode ? '#141414' : '#f0f2f5',
                    color: isDarkMode ? '#bfbfbf' : '#595959'
                }}>
                    ©2024 Created by Fitlap
                </Footer>
            </ConfigProvider>
        </ThemeContext.Provider>
    );
}

export default App;

