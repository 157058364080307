import React, {useEffect, useState} from "react";
import {
    Layout,
    Breadcrumb,
    Input,
    Tabs,
    Table,
    Tag,
    Space,
    Popconfirm,
    message,
    Skeleton,
    Form,
    Select,
    Button
} from 'antd';
import type { SortableContainerProps, SortEnd } from 'react-sortable-hoc';
import type { ColumnsType } from 'antd/es/table';
import { arrayMoveImmutable } from 'array-move';
import {Link, useParams} from "react-router-dom"
import '../css/draggable.scss'
import Api from "../api";

import {CheckOutlined, DeleteOutlined, MenuOutlined} from '@ant-design/icons';
import NewTrainingVideo from "../components/new-training-video";
import NewTrainingVideoCategory from "../components/new-training-video-category";
import EditTrainingVideoCategories from "../components/edit-training-video-categories";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import {redirect} from "../helpers";
import TextArea from "antd/es/input/TextArea";
import ReactQuill from "react-quill";

const {TabPane} = Tabs;
const {Content} = Layout;


const BingoChallengeView = () => {
    const params = useParams();
    const [challenge, setChallenge] = useState([]);
    const [active, setActive] = useState('fitlap');


    useEffect(() => {
        loadChallenge();
    }, [active])


    const loadChallenge = () => {
        Api.get('/admin/bingo/challenges/'  + params.id).then(response => {
            console.log(response.data.data)
            setChallenge(response.data.data);
        })
    };

    const onFinish = (values: any) => {

        let data = {'name': values.name};
        Api.put("/admin/bingo/challenges/" + challenge.id, values).then(res => {
            loadChallenge()
            return message.success('Challenge updated');
        }).catch(e => {
            return message.error('Challenge update failed');
        });
    }

    const deleteChallenge = () => {
        Api.delete('/admin/bingo/challenges/' + challenge.id).then(res => {
            message.success('Challenge deleted');
            redirect("/bingo");
        })
    }

    const modules = {
            toolbar: [
                [{'header': [1, 2, false]}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link'],
                ['clean'],
                ['emoji']
            ],
            "emoji-toolbar": true,
        },
        formats = [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link',
            'emoji'
        ]


    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/bingos">Bingos</Link></Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">
                {!challenge || !challenge.id ? (
                    <Skeleton title={true} loading={true} active/>
                ) : (
                    <Form
                        name="menu-template"
                        labelCol={{span: 5}}
                        initialValues={{
                            name: challenge.name,
                            description: challenge.description,
                            icon: challenge.icon,
                        }}
                        autoComplete="off"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="Name"
                            name="name">
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Description"
                            name="description">
                            <ReactQuill modules={modules} formats={formats}/>
                        </Form.Item>
                        <Form.Item
                            label="Icon URL"
                            name="icon">
                            <Input />
                        </Form.Item>

                        <Form.Item wrapperCol={{offset: 5, span: 16}}>
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>

                            <Popconfirm
                                title={`Delete challenge ${challenge.name}?`}
                                onConfirm={() => deleteChallenge()}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="danger" style={{marginLeft: "1em"}}>
                                    Delete
                                </Button>
                            </Popconfirm>

                        </Form.Item>
                    </Form>
                )}
            </div>
        </Content>
    );
}

export default BingoChallengeView;
