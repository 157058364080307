import React, {useEffect, useState} from "react";
import {Modal, Form, Input, DatePicker, Button, message, Row, Switch, Select, Checkbox} from "antd";
import moment from "moment";
import dayjs from "dayjs";
import Api from "../api";

const {RangePicker} = DatePicker;
const {TextArea} = Input;

const CreateCompetition = ({callback, competitionData, setCompetitionData}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [isPremium, setIsPremium] = useState(false);
    const [isBingo, setIsBingo] = useState(false);
    const [bingos, setBingos] = useState([]);

    useEffect(() => {
        if (competitionData) {
            setIsModalOpen(true);
            setIsPremium(!!competitionData.is_paid); // Set premium checkbox state
            setIsBingo(competitionData.type === 'bingo'); // Set bingo select

            form.setFieldsValue({
                name: competitionData.name,
                image: competitionData.image,
                dates: [dayjs(competitionData.begin_date), dayjs(competitionData.end_date)], // Set start and end date
                enabled: competitionData.enabled,
                is_paid: competitionData.is_paid,
                type: competitionData.type,
                product_id: competitionData.product_id || "",
                bingo_id: competitionData.bingo_id || "",
                summary: competitionData.summary
            });
        } else {
            form.setFieldsValue({
                name: '',
                image: '',
                dates: [], // Set start and end date
                enabled: 0, // Convert enabled to boolean
                is_paid: '',
                type: '',
                product_id: "",
                bingo_id: "",
                summary: "",
            });
        }
    }, [competitionData, form]);

    useEffect(() => {
        Api.get('/admin/v2/bingos').then(res => {
            setBingos(res.data);
        })
    }, [])

    const handleCancel = () => {
        setCompetitionData(null); // Reset competitionData (null to close the modal)
        setIsModalOpen(false);
        setIsPremium(false);
        setIsBingo(false);
        form.resetFields(); // Reset form fields when modal closes
    };

    const handleSubmit = (values) => {
        const {name, image, dates, enabled, summary, type, is_paid, product_id, bingo_id} = values;

        let competitionPayload = {
            name,
            type,
            enabled: enabled,
            summary,
            is_paid: isPremium ? 1 : 0, // Ensure correct value
        };

        if (image) {
            competitionPayload.image = image;
        }

        if (dates) {
            const [startDate, endDate] = dates;

            competitionPayload.begin_date = startDate.format("YYYY-MM-DD");
            competitionPayload.end_date = endDate.format("YYYY-MM-DD");
        }

        if (isPremium) {
            competitionPayload.product_id = product_id;
        }

        if (isBingo) {
            competitionPayload.bingo_id = bingo_id;
        }

        if (type === 'steps') {
            competitionPayload.include_leaderboard = true;
        }

        // If competitionData is provided, update the competition
        const request = competitionData
            ? Api.put(`/admin/competitions/${competitionData.id}`, competitionPayload) // Update
            : Api.post("/admin/competitions", competitionPayload); // Create

        request
            .then((response) => {
                callback();
                message.success(competitionData ? "Competition updated successfully!" : "Competition created successfully!");
                form.resetFields();
                setIsModalOpen(false);
            })
            .catch((error) => {
                message.error("Failed to process competition. Please try again.");
            });
    };

    return (
        <div>
            <Button type="primary" onClick={() => setIsModalOpen(true)}>
                Create Competition
            </Button>

            <Modal
                destroyOnClose={true}
                title={<div
                    style={{textAlign: "center"}}>{competitionData ? "Edit Competition" : "Create New Competition"}</div>}
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null} // Footer is handled by form buttons
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    initialValues={{
                        name: competitionData ? competitionData.name : "",
                        image: competitionData ? competitionData.image : "",
                        dates: competitionData ? [dayjs(competitionData.begin_date), dayjs(competitionData.end_date)] : [],
                        enabled: competitionData ? competitionData.enabled : null, // Set enabled based on competitionData
                    }}
                >
                    <Form.Item
                        name="name"
                        label="Competition Name"
                        rules={[{required: true, message: "Please enter the competition name"}]}
                    >
                        <Input placeholder="Enter competition name"/>
                    </Form.Item>

                    <Form.Item
                        name="image"
                        label="Image url"
                    >
                        <Input placeholder="Image url"/>
                    </Form.Item>

                    <Form.Item
                        name="summary"
                        label="Summary"
                    >
                        <TextArea placeholder="Description"/>
                    </Form.Item>

                    <Form.Item
                        name="dates"
                        label="Start and End Date"
                    >
                        <RangePicker/>
                    </Form.Item>

                    <Form.Item required={true} initialValue={"steps"} name="type" label="Type">
                        <Select onChange={(e) => setIsBingo(e === 'bingo')}>
                            <Select.Option value={"steps"}>Steps</Select.Option>
                            <Select.Option value={"bingo"}>Bingo</Select.Option>
                        </Select>
                    </Form.Item>

                    {isBingo && (
                        <Form.Item required={true} name="bingo_id" label="Bingo">
                            <Select onChange={(e) => setIsBingo(e)}>
                                {bingos.map(bingo => {
                                    return (
                                        <Select.Option value={bingo.id}>{bingo.name}</Select.Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    )}

                    <Form.Item label="" name="is_paid" valuePropName="checked">
                        <Checkbox checked={isPremium} onChange={(e) => setIsPremium(e.target.checked)}>
                            Premium access only
                        </Checkbox>
                    </Form.Item>

                    {isPremium && (
                        <Form.Item
                            name="product_id"
                            label={<div><a target="_blank" href="https://fitlap.ee/amember/admin-products">aMember</a> Product ID</div>}
                            rules={[{required: true, message: "Please enter the product ID for the premium challenge"}]}
                        >
                            <Input placeholder="Enter Product ID"/>
                        </Form.Item>
                    )}

                    <Form.Item
                        name="enabled"
                        valuePropName="checked"
                        initialValue={true} // Default value is enabled (1)
                    >
                        <Switch checkedChildren="Active" unCheckedChildren="Inactive"/>
                    </Form.Item>

                    <Form.Item>
                        <Row type="flex" justify="center">
                            <Button type="primary" htmlType="submit" style={{marginRight: "10px"}}>
                                {competitionData ? "Update" : "Submit"}
                            </Button>
                            <Button onClick={handleCancel}>Cancel</Button>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default CreateCompetition;