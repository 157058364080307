import React, {useEffect, useState} from "react";
import {
    Layout,
    Breadcrumb,
    Input,
    Tabs,
    Table,
    Tag,
    Space,
    Popconfirm,
    message,
    Skeleton,
    Form,
    Select,
    Button
} from 'antd';
import {Link, useParams} from "react-router-dom"
import '../css/draggable.scss'
import Api from "../api";
import {redirect} from "../helpers";

const {TabPane} = Tabs;
const {Content} = Layout;


const BingoChallengeCategoryView = () => {
    const params = useParams();
    const [category, setCategory] = useState([]);
    const [active, setActive] = useState('fitlap');

    useEffect(() => {
        loadCategory();
    }, [active])


    const loadCategory = () => {
        Api.get('/admin/bingo/categories/'  + params.id).then(response => {
            console.log(response.data.data)
            setCategory(response.data.data);
        })
    };

    const onFinish = (values: any) => {

        let data = {'name': values.name};
        Api.put("/admin/bingo/categories/" + category.id, values).then(res => {
            loadCategory()
            return message.success('Category updated');
        }).catch(e => {
            return message.error('Category update failed');
        });
    }

    const deleteCategory = () => {
        Api.delete('/admin/bingo/categories/' + category.id).then(res => {
            redirect("/bingo");
            message.success('Category deleted');
        })
    }

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/bingos">Bingos</Link></Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">
                {!category || !category.id ? (
                    <Skeleton title={true} loading={true} active/>
                ) : (
                    <Form
                        name="menu-template"
                        labelCol={{span: 5}}
                        initialValues={{
                            name: category.name,
                        }}
                        autoComplete="off"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="Name"
                            name="name">
                            <Input />
                        </Form.Item>

                        <Form.Item wrapperCol={{offset: 5, span: 16}}>
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>

                            <Popconfirm
                                title={`Delete category ${category.name}?`}
                                onConfirm={() => deleteCategory()}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="danger" style={{marginLeft: "1em"}}>
                                    Delete
                                </Button>
                            </Popconfirm>

                        </Form.Item>
                    </Form>
                )}
            </div>
        </Content>
    );
}

export default BingoChallengeCategoryView;
