import React, {useEffect, useState} from "react";
import {
    Avatar,
    Breadcrumb,
    Button, Card,
    Checkbox,
    Divider,
    Form,
    Image,
    Input,
    InputNumber,
    Layout,
    List,
    message,
    Popconfirm,
    Row,
    Select,
    Skeleton,
    Space,
    Switch,
    Table,
    Tabs,
    Tag, Typography
} from 'antd';
import {Link, useParams} from 'react-router-dom';
import Api from "../api";
import {CheckCircleOutlined, FileSearchOutlined} from "@ant-design/icons";
import config from "../config/config";
import TextArea from "antd/es/input/TextArea";
import IngredientIconPicker from "../components/ingredient-icon-picker";
import moment from "moment/moment";
const {Title} = Typography;
const {Content} = Layout;

const IngredientView = () => {
    const params = useParams();
    const [ingredient, setIngredient] = useState('');
    const [ingredientRecipes, setIngredientRecipes] = useState(false);
    const [active, setActive] = useState('fitlap');
    const [categories, setCategories] = useState(false);
    const [subGroups, setSubGroups] = useState(false);
    const [customUnits, setCustomUnits] = useState([]);
    const [allergens, setAllergens] = useState([]);
    const [nutriData, setNutriData] = useState([]);
    const [history, setHistory] = useState([]);
    const [ingredientImage, setIngredientImage] = useState('');
    const [form] = Form.useForm();
    const [activeKey, setActiveKey] = useState("item-1");

    useEffect(() => {
        getFunctions();
        Api.get('/admin/ingredients/' + params.id).then(response => {
            setIngredient(response.data);
            setIngredientRecipes(response.data.recipes)
        })
    }, [])

    useEffect(() => {
        Api.get(`/admin/ingredients/${params.id}/history`).then(response => {
            setHistory(response.data);
        })
    }, [])

    useEffect(() => {
        if (activeKey === "item-1" && ingredient) {
            form.resetFields();

            form.setFieldsValue({
                barcode: ingredient.barcode[0] ? ingredient.barcode[0].barcode : "",
                original_name: ingredient.original_name,
                short_name: ingredient.short_name,
                image: ingredient.image,
                partitive_name: ingredient.partitive_name,
                private_name: ingredient.private_name,
                ingredient_category_id: ingredient.ingredient_category_id,
                substitute_group: ingredient.substitute_group,
                simple_substitute_group: ingredient.simple_substitute_group,
                custom_calc_amount: ingredient.custom_calc_amount,
                custom_unit: ingredient.custom_unit,
                custom_unit_value: ingredient.custom_unit_value,
                price: ingredient.price * 10,
                price_range: ingredient.price_range,
                energy: ingredient.energy,
                display_format: displayFormatChecked(),
                round: roundingChecked(),
                fat: ingredient.fat,
                protein: ingredient.protein,
                carbohydrate: ingredient.carbohydrate,
                polyols: ingredient.polyols,
                erytriol: ingredient.erytriol,
                fiber: ingredient.fiber,
                status: ingredient.status,
                verified: ingredient.verified,
                cooked_amount_factor: ingredient.cooked_amount_factor,
                order_url: ingredient.order_url ? ingredient.order_url : "",
                logo_image: ingredient.logo_image,
                logo_landing_page: ingredient.logo_landing_page,
                no_limit: ingredient.no_limit,
                is_egg: ingredient.is_egg,
                is_promoted: ingredient.is_promoted,
                notes: ingredient.notes,
                keywords: ingredient.keywords,
                sku_code: ingredient.sku_code,
                intolerances: intoleranceChecked(),
            });
        }
    }, []);

    const getFunctions = () => {
        if (categories === false) {
            Api.get('/admin/functions').then(response => {
                setCategories(response.data.categories);
                setSubGroups(response.data.substituteGroups);
                setAllergens(response.data.allergens);
                handleCustomUnits(response.data.customUnits)
            })
        }
    }

    const handleCustomUnits = (customUnits) => {
        const formatted = [];
        customUnits.forEach((el) => {
            setCustomUnits(current => [...current, {'value': el.id, 'label': el.singular}])
        })
        console.log(categories)
    }
    const onChange = (e) => {

    }
    const onChangeBarcode = (e) => {
        const barcode = e.target.value;
        if (barcode.length >= 8) {
            console.log(barcode)
        }
    }

    const onFinishIngredient = (values: any) => {

        values.price = values.price / 10;
        values.order_url = values.order_url !== "" ? values.order_url : "";
        values.nutridata = {
            fiber: values.fiber
        }
        console.log('Success:', values);

        Api.put("/admin/ingredients/" + ingredient.id, values).then(res => {
            verifyIngredientMacros(ingredient.id);
            return message.success('Ingredient updated');
        }).catch(e => {
            return message.error('Ingredient update failed');
        });
    };

    const onFinishNutridata = (values: any) => {
        console.log('Success:', values);

        Api.put("/admin/ingredients/" + ingredient.id + "/nutridata", values).then(res => {
            verifyIngredientMacros(ingredient.id);
            return message.success('Ingredient updated');
        }).catch(e => {
            return message.error('Ingredient update failed');
        });
    };

    const onFinishAllergens = (values: any) => {
        console.log('Success:', values);

        Api.put("/admin/ingredients/" + ingredient.id + "/allergens", values).then(res => {
            return message.success('Ingredient updated');
        }).catch(e => {
            return message.error('Ingredient update failed');
        });
    };

    const onFinishFailed = (errorInfo: any) => {
        return message.error('Failed: please fix the shown errors');
    };

    const verifyIngredientMacros = (ingredient) => {
        Api.get('/admin/ingredients/' + ingredient + '/verify').then(res => {
            if (res.data === 1) {
                message.success('Ingredient macros are in spec');
            } else {
                message.error('Ingredient macros are out of spec');
            }

        })
    }

    const forceVerify = (ingredient) => {
        Api.put('/admin/ingredients/' + ingredient.id + '/verify').then(res => {
            if (res.data.verified === 1) {
                message.success(res.data.original_name + " has been manually set to verified");
            } else {
                message.error('Error occurred, contact IT');
            }
        })
    }

    const allergensDefaultValues = () => {
        const data = [];
        allergens.map((allergen) => (
            isAllergenChecked(allergen) ? data.push(allergen.id) : false
        ))

        return data;
    }

    // Set which checkboxes are ticked by default
    const displayFormatChecked = () => {
        const data = [];

        if (ingredient.display_format === 1) {
            data.push(1)
        }

        if (ingredient.display_format === 2) {
            data.push(2)
        }

        if (ingredient.display_format === 3) {
            data.push(1)
            data.push(2)
        }

        return data;
    }

    // Set which checkboxes are ticked by default
    const roundingChecked = () => {
        const data = [];

        if (ingredient.round === 1) {
            data.push(1)
        }

        if (ingredient.round === 2) {
            data.push(2)
        }

        if (ingredient.round === 3) {
            data.push(1)
            data.push(2)
        }

        if (ingredient.round === 4) {
            data.push(4)
        }

        return data;
    }

    const hasBitwise = (values, value) => {
        if (values === 0) {
            return false
        }
        return ((values & value) === value);
    }

    const intoleranceChecked = () => {
        if (ingredient.intolerances === 0) {
            return false;
        }
        let checked = []
        {
            intolerances.map(intolerance => (
                intolerance.value === config.intolerances.gluten_free || intolerance.value === config.intolerances.lactose_free ?
                    !hasBitwise(ingredient.intolerances, intolerance.value) ? checked.push(intolerance.value) : null
                    :
                    hasBitwise(ingredient.intolerances, intolerance.value) ? checked.push(intolerance.value) : null
            ))
        }
        return checked
    }

    // Disabled, not sure if still needed
    // const intoleranceAlternativesChecked = () => {
    //     let checked = []
    //     {intolerance_alternatives.map(intolerance => (
    //         hasBitwise(ingredient.intolerance_alternatives, intolerance.value) ? checked.push(intolerance.value) : null
    //     ))}
    //     console.log(intolerance_alternatives)
    //     console.log(checked)
    //
    //     return checked
    // }


    const intolerances = [
        {label: "Sisaldab gluteeni", value: config.intolerances.gluten_free},
        {label: "Sisaldab laktoosi", value: config.intolerances.lactose_free},
        {label: "Taimetoit", value: config.intolerances.vegeterian},
        {label: "Vegan", value: config.intolerances.vegan},
    ];

    const isAllergenChecked = (allergen) => {
        return allergen.ingredients.includes(ingredient.id) ? true : false
    }

    const priceRange = [
        {value: 0, label: '-- Vali --'},
        {value: 1, label: 'Odav'},
        {value: 2, label: 'Keskmine'},
        {value: 3, label: 'Kallis'},
    ]

    const logger = () => {
        console.log(ingredient)
    }

    const syncIngredientFromHistory = (record) => {
        Api.put(`/admin/ingredients/${record.ingredient_id}/history/` + record.id).then(response => {
            message.success('Synced');

            // Reload recipe
            Api.get('/admin/ingredients/' + params.id).then(response => {
                setIngredient(response.data);
                setIngredientRecipes(response.data.recipes)
            })
        })
    }

    console.log(ingredientRecipes)

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/ingredients">Ingredients</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{params.id}</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">
                <Tabs activeKey={activeKey} onChange={setActiveKey}>
                    <Tabs.TabPane tab="Toiduaine" key="item-1">
                        {!subGroups || !ingredient ? (
                            <Skeleton title={true} loading={true} active/>
                        ) : (
                            <Form
                                key={ingredient}
                                name="ingredient"
                                form={form}
                                labelCol={{span: 8}}
                                wrapperCol={{span: 16}}
                                initialValues={{
                                    barcode: ingredient.barcode[0] ? ingredient.barcode[0].barcode : "",
                                    original_name: ingredient.original_name,
                                    short_name: ingredient.short_name,
                                    image: ingredient.image,
                                    partitive_name: ingredient.partitive_name,
                                    private_name: ingredient.private_name,
                                    ingredient_category_id: ingredient.ingredient_category_id,
                                    substitute_group: ingredient.substitute_group,
                                    simple_substitute_group: ingredient.simple_substitute_group,
                                    custom_calc_amount: ingredient.custom_calc_amount,
                                    custom_unit: ingredient.custom_unit,
                                    custom_unit_value: ingredient.custom_unit_value,
                                    price: ingredient.price * 10,
                                    price_range: ingredient.price_range,
                                    energy: ingredient.energy,
                                    display_format: displayFormatChecked(),
                                    round: roundingChecked(),
                                    fat: ingredient.fat,
                                    protein: ingredient.protein,
                                    carbohydrate: ingredient.carbohydrate,
                                    polyols: ingredient.polyols,
                                    erytriol: ingredient.erytriol,
                                    fiber: ingredient.fiber,
                                    status: ingredient.status,
                                    verified: ingredient.verified,
                                    cooked_amount_factor: ingredient.cooked_amount_factor,
                                    order_url: ingredient.order_url ? ingredient.order_url : "",
                                    logo_image: ingredient.logo_image,
                                    logo_landing_page: ingredient.logo_landing_page,
                                    no_limit: ingredient.no_limit,
                                    is_egg: ingredient.is_egg,
                                    is_promoted: ingredient.is_promoted,
                                    notes: ingredient.notes,
                                    keywords: ingredient.keywords,
                                    sku_code: ingredient.sku_code,
                                    intolerances: intoleranceChecked(),
                                    // intolerance_alternatives: intoleranceAlternativesChecked()
                                }}
                                onFinish={onFinishIngredient}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >

                                <Form.Item
                                    label="Barcode"
                                    name="barcode"
                                >
                                    <Input onChange={onChangeBarcode}/>
                                </Form.Item>


                                {/*New*/}
                                <Form.Item
                                    label="Avalik nimi"
                                    name="original_name"
                                    rules={[{required: true, message: 'Palun sisesta toote avalik nimi'}]}>
                                    <Input/>
                                </Form.Item>

                                <Form.Item
                                    label="Lühike nimi"
                                    name="short_name">
                                    <Input/>
                                </Form.Item>

                                <Form.Item
                                    label="Ikoon"
                                    name="image">
                                    <Input/>
                                </Form.Item>

                                <Form.Item
                                    label="Eelvaade"
                                    name="preview_image">
                                    <div style={{justifyContent: "center"}}>
                                        <Image src={ingredientImage ? ingredientImage : ingredient.image} width={40}/>
                                        <IngredientIconPicker setIngredient={setIngredient}
                                                              setIngredientImage={setIngredientImage}
                                                              ingredient={ingredient} form={form}/>
                                    </div>
                                </Form.Item>


                                <Form.Item
                                    label="Osastavas käändes nimi"
                                    name="partitive_name">
                                    <Input/>
                                </Form.Item>

                                <Form.Item
                                    label="Privaatne nimi"
                                    name="private_name">
                                    <Input/>
                                </Form.Item>

                                <Form.Item label="Kategooria" name="ingredient_category_id"
                                           rules={[{required: true, message: 'Palun vali toote kategooria'}]}>
                                    <Select options={categories} value={ingredient.ingredient_category_id}>
                                    </Select>
                                </Form.Item>

                                <Form.Item label="Asenduste grupp" name="substitute_group">
                                    <Select options={[
                                        {value: 0, label: '-- vali --'},
                                        ...subGroups.filter(subGroup => !subGroup.label.includes('Lihtne'))
                                    ]}
                                            value={ingredient.simple_substitute_group}>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Lihtne Asenduste grupp" name="simple_substitute_group">
                                    <Select options={[
                                        {value: null, label: '-- vali --'},
                                        ...subGroups.filter(subGroup => subGroup.label.includes('Lihtne'))
                                    ]}
                                            value={ingredient.simple_substitute_group}>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Energia (kcal)"
                                    name="energy"
                                >
                                    <InputNumber
                                        defaultValue="0"
                                        min="0"
                                        step="0.01"
                                        stringMode
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Süsivesikud (g)"
                                    name="carbohydrate">
                                    <InputNumber
                                        defaultValue="0"
                                        min="0"
                                        step="0.01"
                                        stringMode
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Polüoolid (g)"
                                    name="polyols">
                                    <InputNumber
                                        defaultValue="0"
                                        min="0"
                                        step="0.01"
                                        stringMode
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Erütriool (g)"
                                    name="erytriol">
                                    <InputNumber
                                        defaultValue="0"
                                        min="0"
                                        step="0.01"
                                        stringMode
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Kiudained (g)"
                                    name="fiber">
                                    <InputNumber
                                        defaultValue="0"
                                        min="0"
                                        step="0.01"
                                        stringMode
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Valgud (g)"
                                    name="protein">

                                    <InputNumber
                                        defaultValue="0"
                                        min="0"
                                        step="0.01"
                                        stringMode
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Rasvad (g)"
                                    name="fat">
                                    <InputNumber
                                        defaultValue="0"
                                        min="0"
                                        step="0.01"
                                        stringMode
                                    />
                                </Form.Item>


                                <Form.Item
                                    label="Hind (kg)"
                                    name="price">
                                    <InputNumber
                                        defaultValue="0"
                                        min="0"
                                        step="0.01"
                                        stringMode
                                    />
                                </Form.Item>

                                <Form.Item label="Hinna klass" name="price_range">
                                    <Select options={priceRange} value={ingredient.price_range}/>
                                </Form.Item>

                                <Form.Item
                                    label="Minimaalne kogus (g)"
                                    name="min_amount"
                                >
                                    <InputNumber
                                        defaultValue="0"
                                        min="0"
                                        step="0.01"
                                        stringMode
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Toidukalkulaatori vaikimisi kogus (g)"
                                    name="custom_calc_amount"
                                >
                                    <InputNumber
                                        defaultValue="0"
                                        min="0"
                                        step="0.01"
                                        stringMode
                                    />
                                </Form.Item>

                                <Form.Item label="Visible" name="status" valuePropName="checked">
                                    <Switch/>
                                </Form.Item>

                                <Divider/>
                                <Form.Item label="Kuvamise formaat" name="display_format">
                                    <Checkbox.Group style={{width: '100%'}} onChange={onChange}>
                                        <Row>
                                            <Checkbox value={config.unit_system.display_amount}>Näita kogust</Checkbox>
                                        </Row>
                                        <Row>
                                            <Checkbox value={config.unit_system.display_custom_amount}>Näita mõõtühiku
                                                kogust</Checkbox>
                                        </Row>
                                    </Checkbox.Group>

                                </Form.Item>

                                <Divider/>

                                <Form.Item label="Mõõtühik" name="custom_unit">
                                    <Select options={customUnits} value={ingredient.custom_unit}></Select>
                                </Form.Item>

                                <Form.Item
                                    label="1 mõõtühik = ? g"
                                    name="custom_unit_value"
                                >
                                    <InputNumber
                                        defaultValue="0"
                                        min="0"
                                        step="0.01"
                                        stringMode
                                    />
                                </Form.Item>

                                <Form.Item label="Ümardamine" name="round">
                                    <Checkbox.Group style={{width: '100%'}} onChange={onChange}>
                                        <Row>
                                            <Checkbox value={config.rounding.up}>Ümarda üles</Checkbox>
                                        </Row>
                                        <Row>
                                            <Checkbox value={config.rounding.down}>Ümarda alla</Checkbox>
                                        </Row>
                                        <Row>
                                            <Checkbox value={config.rounding.disabled}>Ei ümarda</Checkbox>
                                        </Row>
                                        <span><small>Ümarda üles ja alla mõjutab ainult mõõtühiku kogust.
Kui ainult 'ümarda alla' on valitud ja ümardamise tulemuseks on 0, siis see muudetakse 1.
'Ei ümarda' tühistab ainult koguse ümardamist, mitte mõõtühiku kogust.</small></span>
                                    </Checkbox.Group>
                                </Form.Item>

                                <Form.Item
                                    label="Valmis koguse kordaja"
                                    name="cooked_amount_factor"
                                >
                                    <InputNumber
                                        defaultValue="0"
                                        min="0"
                                        step="0.01"
                                        stringMode
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Ostulink (URL)"
                                    name="order_url">
                                    <Input/>
                                </Form.Item>

                                <Form.Item
                                    label="Logo (URL)"
                                    name="logo_landing_page">
                                    <Input/>
                                </Form.Item>

                                <Form.Item
                                    label="Logo pilt (URL) (~50 x 30 px)"
                                    name="logo_image">
                                    <Input/>
                                </Form.Item>

                                <Divider/>

                                <Form.Item label="Vabavara" name="no_limit" valuePropName="checked">
                                    <Checkbox value="1"></Checkbox>
                                </Form.Item>
                                <Form.Item label="Muna" name="is_egg" valuePropName="checked">
                                    <Checkbox value="1"></Checkbox>
                                </Form.Item>

                                <Divider/>


                                <Form.Item name="intolerances" label="Välistused">
                                    <Checkbox.Group style={{width: '100%'}}>
                                        {intolerances.map(intolerance => (
                                            <Row>
                                                <Checkbox value={intolerance.value}>{intolerance.label}</Checkbox>
                                            </Row>
                                        ))}
                                    </Checkbox.Group>
                                </Form.Item>

                                {/*<Form.Item name="intolerance_alternatives">*/}
                                {/*    <Checkbox.Group>*/}
                                {/*        {intolerance_alternatives.map(intolerance => (*/}
                                {/*            <Row>*/}
                                {/*                <Checkbox value={intolerance.value}>{intolerance.label}</Checkbox>*/}
                                {/*            </Row>*/}
                                {/*        ))}*/}
                                {/*    </Checkbox.Group>*/}
                                {/*</Form.Item>*/}

                                <Divider/>
                                <Form.Item label="Esiletõstetud" name="is_promoted" valuePropName="checked">
                                    <Switch/>
                                </Form.Item>

                                <Divider/>

                                <Form.Item
                                    label="Selver SKU kood"
                                    name="sku_code">
                                    <Input/>
                                </Form.Item>

                                <Form.Item
                                    label="Märkmed"
                                    name="notes">
                                    <TextArea/>
                                </Form.Item>

                                <Form.Item
                                    label="Keywords"
                                    name="keywords">
                                    <TextArea/>
                                </Form.Item>

                                {/*End New*/}

                                <Form.Item label="Verified"
                                           name="verified">
                                    <Tag
                                        style={ingredient.verified === 1 ? {color: 'green'} : {color: "red"}}>{ingredient.verified ? "Verified" : "Unverified"}</Tag>
                                </Form.Item>


                                <Form.Item wrapperCol={{offset: 8, span: 16}}>
                                    <Space>
                                        <Button type="primary" htmlType="submit">
                                            Submit
                                        </Button>

                                        <Button type="default" onClick={() => verifyIngredientMacros(ingredient.id)}
                                                icon={<FileSearchOutlined/>}>
                                            Check macros
                                        </Button>

                                        <Popconfirm
                                            title={`Force verified status on: ${ingredient.original_name}?`}
                                            onConfirm={() => forceVerify(ingredient)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button danger icon={<CheckCircleOutlined/>}>
                                                Force verify
                                            </Button>
                                        </Popconfirm>

                                    </Space>
                                </Form.Item>
                            </Form>
                        )}
                    </Tabs.TabPane>


                    <Tabs.TabPane tab="Nutridata" key="item-2">
                        <Form
                            name="nutridata"
                            labelCol={{span: 8}}
                            wrapperCol={{span: 16}}
                            initialValues={
                                ingredient.nutridata
                            }
                            onFinish={onFinishNutridata}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            {Object.entries(config.nutridata_fields).map(([key, value]) => (
                                <Form.Item
                                    label={value}
                                    name={key}
                                >
                                    <InputNumber
                                        defaultValue="0"
                                        min="0"
                                        step="0.01"
                                        stringMode
                                    />
                                </Form.Item>
                            ))}
                            <Form.Item wrapperCol={{offset: 8, span: 16}}>
                                <Space>
                                    <Button type="primary" htmlType="submit">
                                        Salvesta
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </Tabs.TabPane>


                    <Tabs.TabPane tab="Allergeenid" key="item-3">
                        <Form
                            name="allergens"
                            labelCol={{span: 4}}
                            wrapperCol={{span: 20}}
                            initialValues={{
                                allergens: allergensDefaultValues()
                            }}
                            onFinish={onFinishAllergens}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >

                            <Form.Item label="Allergeenid" name="allergens">
                                <Checkbox.Group style={{width: '100%'}}>
                                    {
                                        allergens.map((allergen) => (
                                            <div>
                                                <Checkbox value={allergen.id}>{allergen.title}</Checkbox>
                                            </div>
                                        ))

                                    }
                                </Checkbox.Group>

                            </Form.Item>

                            <Form.Item wrapperCol={{offset: 8, span: 16}}>
                                <Space>
                                    <Button type="primary" htmlType="submit">
                                        Salvesta
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </Tabs.TabPane>


                    <Tabs.TabPane tab="Retseptid" key="item-4">
                        <List
                            grid={{ gutter: 16, column: 4 }}
                            dataSource={ingredientRecipes}
                            renderItem={item => (
                                <a href={"/recipe/" + item.id}>
                                    <List.Item>
                                        <Card
                                            cover={<img src={item.image} alt=""/>}
                                            title=""><Title level={4} style={{margin: 0}}>{item.original_name}</Title> <br/> {moment(item.created_at).format('DD.MM.YYYY')}</Card>
                                    </List.Item>
                                </a>
                            )}
                        />
                    </Tabs.TabPane>

                    <Tabs.TabPane tab="Ajalugu" key="item-5">
                        <Table dataSource={history} columns={[
                            {
                                title: 'original_name',
                                dataIndex: 'original_name',
                                key: 'original_name',
                            },
                            {
                                title: 'carbohydrate',
                                dataIndex: 'carbohydrate',
                                key: 'carbohydrate',
                            },
                            {
                                title: 'energy',
                                dataIndex: 'energy',
                                key: 'energy',
                            },
                            {
                                title: 'fat',
                                dataIndex: 'fat',
                                key: 'fat',
                            },
                            {
                                title: 'fiber',
                                dataIndex: 'fiber',
                                key: 'fiber',
                            },
                            {
                                title: 'protein',
                                dataIndex: 'protein',
                                key: 'protein',
                            },
                            {
                                title: 'created_at',
                                dataIndex: 'created_at',
                                key: 'created_at',
                                render: value => <span>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</span>
                            },
                            {
                                title: 'updated_at',
                                dataIndex: 'updated_at',
                                key: 'updated_at',
                                render: value => <span>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</span>
                            },
                            {
                                title: 'id',
                                dataIndex: 'id',
                                key: 'id',
                            },
                            {
                                title: 'ingredient_id',
                                dataIndex: 'ingredient_id',
                                key: 'ingredient_id',
                            },
                            {
                                title: 'user_id',
                                dataIndex: 'user_id',
                                key: 'user_id',
                            },
                            {
                                title: "Action",
                                key: "action",
                                render: (text, record) =>
                                    <Popconfirm
                                        title={`Are you sure you want to sync from this history?`}
                                        onConfirm={() => syncIngredientFromHistory(record)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button type="primary">Sync</Button>
                                    </Popconfirm>
                            },
                        ]}/>
                    </Tabs.TabPane>
                </Tabs>


            </div>
        </Content>
    );
}

export default IngredientView;