import React, {useEffect, useState} from "react";
import {
    Layout,
    Breadcrumb,
    Row,
    Col,
    Input,
    Button,
    Skeleton,
    List,
    Upload,
    Form,
    Image,
    Space,
    Divider,
    Select, InputNumber, Checkbox, Table, Typography, message, Alert, Popconfirm, Switch
} from 'antd';
import {DeleteOutlined, EyeInvisibleOutlined, StarOutlined, UploadOutlined} from "@ant-design/icons";
import {useParams, Link, useNavigate, useLocation} from 'react-router-dom';
import TextArea from "antd/es/input/TextArea";
import Api from "../api";
import config from "../config/config";
import ReactQuill from "react-quill";
import Search from "antd/lib/input/Search";
import {set, setIn} from "immutable";
import IngredientList from "../components/ingredient-list";
import IngredientSearch from "../components/ingredient-search";
import {redirect} from "../helpers";
import axios from "axios";

const {Option} = Select;
const {Title} = Typography;
const {Content} = Layout;

const getBase64 = (img, callback) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const RecipeView = () => {
    const location = useLocation();
    const params = useParams();
    const [recipe, setRecipe] = useState('');
    const [categories, setCategories] = useState(false);
    const [mealTypes, setMealTypes] = useState('');
    const [search, setSearch] = useState([]);
    const [ingredients, setIngredients] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [loadingImageGeneration, setLoadingImageGeneration] = useState(false);
    const [imgFile, setImgFile] = useState(false);
    const [image, setImage] = useState(false);
    const isEditRecipe = !!params.id;
    const [form] = Form.useForm();
    const navigate = useNavigate();

    useEffect(() => {
        if (isEditRecipe) {
            setLoading(true);

            Api.get('/admin/recipes/' + params.id).then(res => {
                setMealTypes(getMealTypes(res.data.data))
                setRecipe(res.data.data)
                setIngredients(res.data.data.ingredients)
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                setError(true);
            })
        }

        loadCategories();
    }, [])

    const loadCategories = () => {
        if (categories === false) {
            Api.get('/admin/recipes/functions/categories').then(res => {
                setCategories(res.data)
            })
        }
    }

    const hasBitwise = (values, value) => {
        if (values === 0) {
            return false
        }
        return ((values & value) === value);
    }

    const getMealTypes = (test) => {
        const types = [];


        if (hasBitwise(test.meal_type, config.mealTypes.breakfast)) {
            types.push(config.mealTypes.breakfast)
        }
        if (hasBitwise(test.meal_type, config.mealTypes.lunch)) {
            types.push(config.mealTypes.lunch)
        }
        if (hasBitwise(test.meal_type, config.mealTypes.dinner)) {
            types.push(config.mealTypes.dinner)
        }
        if (hasBitwise(test.meal_type, config.mealTypes.supper)) {
            types.push(config.mealTypes.supper)
        }
        console.log("meal types:" + types)

        return types
    }

    const calculateMealType = (recipe) => {
        let value = 0;

        if (recipe.breakfast) {
            value += config.mealTypes.breakfast;
        }

        if (recipe.lunch_and_dinner) {
            value += config.mealTypes.lunch;
            value += config.mealTypes.dinner;
        }
        if (recipe.supper) {
            value += config.mealTypes.supper;
        }

        return value;
    }

    const modules = {
            toolbar: [
                [{'header': [1, 2, false]}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link'],
                ['clean'],
                ['emoji']
            ],
            "emoji-toolbar": true,
        },
        formats = [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link',
            'emoji'
        ]

    const forVeganOnlyOptions = [
        {label: "-- Vali --", value: 0},
        {label: "1 - Sobib ka Veganile", value: 1},
        {label: "2 - Ainult Veganile", value: 2},
    ]

    const uploadImage = (value) => {
        setImgFile(value.file.originFileObj);

        getBase64(value.file.originFileObj, imageUrl => {
            setImage(imageUrl);
        });
    }

    const setIngredient = (ingredient) => {
        const isFound = ingredients.some(element => {
            return element.id === ingredient.id
        })

        if (isFound) {
            return message.error(`Toiduaine on juba nimekirjas`);
        } else {
            ingredient.pivot_energy = 0;
            ingredient.amount = 0;
            setIngredients(current => [...current, ingredient])
            setSearch([])
        }
    }

    function handleIsSimpleChange(event) {
        const updatedData = setIn(recipe, ['is_simple'], event.target.checked)
        setRecipe(updatedData)
    }

    const onFinish = (values: any) => {
        values.ingredients = {...ingredients};
        values.meal_type = calculateMealType(values)
        console.log('Success:', values);

        if (values.instructions) {
            values.instructions = updateLinkTargets(values.instructions, 'fitlap.ee');
        }

        const updateRecipeImage = (recipeId) => {
            if (imgFile) {
                const formData = new FormData();

                // Update the formData object
                formData.append("image", imgFile);

                Api.post('admin/recipes/' + recipeId + '/image', formData).then(res => {
                    message.success(`Image updated`);
                }).catch(e => {
                    message.error(`Image update failed`);
                });
            }
        }

        if (isEditRecipe) {
            Api.put('admin/recipes/' + recipe.id, values).then(res => {
                updateRecipeImage(recipe.id);

                return message.success(`Retsept edukalt uuendatud!`);
            }).catch(e => {
                return message.error(`${e.message}`);
            });
        } else {
            Api.post('admin/recipes', values).then(res => {
                updateRecipeImage(res.data.id);

                message.success(`Retsept edukalt lisatud!`);

                setTimeout(() => {
                    navigate('/recipes');
                }, [1000])
            }).catch(e => {
                return message.error(`${e.message}`);
            });
        }
    };

    function updateLinkTargets(htmlString, domain) {
        // Create a temporary DOM element to manipulate the HTML string
        const tempDiv = document.createElement('div');

        tempDiv.innerHTML = htmlString;

        // Get all anchor tags in the temporary DOM element
        const links = tempDiv.querySelectorAll('a');

        links.forEach(link => {
            const url = link.href;

            // Check if the link contains the specified domain
            if (url.includes(domain)) {
                link.setAttribute('target', '_self'); // Open in the same tab
            } else {
                link.setAttribute('target', '_blank'); // Open in a new tab
                link.setAttribute('rel', 'noopener noreferrer'); // Security feature
            }
        });

        // Return the updated HTML string
        return tempDiv.innerHTML;
    }

    const recipeImage = () => {
        if (image) {
            return image;
        }

        return recipe ? recipe.image.replace('thumbnail', 'original') : '';
    }

    const queryImageModel = (data) => {
        return axios.post(
            "https://api-inference.huggingface.co/models/black-forest-labs/FLUX.1-schnell",
            data,
            {
                headers: {
                    Authorization: "Bearer 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",
                    "Content-Type": "application/json",
                },
                responseType: 'blob' // Ensure that the response is handled as a blob
            }).then(response => {
            // Handle the response here
            return response.data;
        }).catch(error => {
            // Handle the error here
            console.error('Error making request:', error);
            throw error; // Rethrow the error if you want to handle it further up the call chain
        });
    }

    const createImageUrl = (blob) => {
        return URL.createObjectURL(blob);
    }

    // Function to generate prompt using ChatGPT
    const queryChatGPT = (message, OpenAIApiKey, model = 'gpt-3.5-turbo') => {
        return axios.post(
            'https://api.openai.com/v1/chat/completions',
            {
                model: model,
                messages: [
                    { role: 'system', content: 'You are a helpful assistant.' },
                    { role: 'user', content: message }
                ],
                temperature: 0.7,
                max_tokens: 1000
            },
            {
                headers: {
                    Authorization: `Bearer ${OpenAIApiKey}`,
                    'Content-Type': 'application/json'
                }
            }
        ).then(response => {
            return response.data.choices[0].message.content; // The generated prompt
        }).catch(error => {
            console.error('Error querying ChatGPT:', error);
            throw error;
        });
    };

    const forceToNewest = () => {
        Api.post('admin/recipes/' + recipe.id + '/force-newest').then(res => {
            message.success(`Retsept edukalt lisatud!`);

            setTimeout(() => {
                navigate('/recipes');
            }, [1000])
        }).catch(e => {
            return message.error(`${e.message}`);
        });
    }

    const generateRecipeImage = () => {
        const OpenAIApiKey = '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';
        const formattedIngredients = ingredients.map(ingredient =>
            `${ingredient.original_name ? ingredient.original_name : ingredient.name}`
        ).join(', ');

        const recipeName = form.getFieldValue('original_name');

        if (!recipeName) {
            return message.error('Retsepti nimi nõutud!')
        }

        setLoadingImageGeneration(true);

        // Generate the prompt using ChatGPT
        queryChatGPT(`Generate a detailed prompt for a text-to-image model to create an image of a meal called "${recipeName}" with the following ingredients: ${formattedIngredients}. Do not respond with anything besides prompt that i can use and max length is 1000 characters`, OpenAIApiKey)
            .then(prompt => {
                // Generate the image using the prompt from ChatGPT
                return queryImageModel({"inputs": prompt});
            })
            .then(response => {
                const url = createImageUrl(response);

                message.success('Image generated!');

                setImage(url);
                setImgFile(response);
                setLoadingImageGeneration(false);
            })
            .catch(error => {
                message.error('Failed to generate image');
                setLoadingImageGeneration(false);
            });
    };

    if (isEditRecipe && !recipe) {
        return false;
    }

    return (
        <Content key={location.key} style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/recipes">Recipes</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{params.id}</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">
                <Divider orientation="left">
                    <Title style={{margin: 0}} level={4}>{isEditRecipe ? "Muuda retsepti" : "Loo retsept"}</Title>
                </Divider>

                {error && (
                    <Row type="flex" justify="center">
                        <Space direction="vertical" style={{textAlign: "center"}}>
                            <Alert type="error" message="Recipe not found" showIcon={true}></Alert>
                            <Button type="primary"><Link to="/recipes">Go Back</Link></Button>
                        </Space>
                    </Row>
                )}

                <div>
                    <Form
                        labelCol={{span: 5}}
                        wrapperCol={{span: 16}}
                        name={"image-upload"}
                    >
                        <Form.Item
                            label="Retsepti pilt"
                            name="image"
                        >
                            <Space direction={"vertical"}>
                                <Image style={{maxWidth: 360}} src={recipeImage()}></Image>

                                <Upload onChange={uploadImage}>
                                    <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                                </Upload>

                                <Button loading={loadingImageGeneration} onClick={generateRecipeImage} icon={<StarOutlined/>}>Generate AI Image</Button>
                            </Space>
                        </Form.Item>
                    </Form>

                    <Form
                        name="recipe"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 16}}
                        key={recipe}
                        initialValues={{
                            original_name: recipe.original_name,
                            slug: recipe.slug,
                            seo_title: recipe.seo_title,
                            seo_description: recipe.seo_description,
                            preparation_time: recipe.preparation_time,
                            is_active: recipe.is_active,
                            is_public: recipe.is_public,
                            is_featured: recipe.is_featured,
                            is_basic: recipe.is_basic,
                            is_simple: recipe.is_simple,
                            is_from_store: recipe.is_from_store,
                            is_restaurant_meal: recipe.is_restaurant_meal,
                            breakfast: mealTypes.includes(config.mealTypes.breakfast) ? 'checked' : null,
                            lunch_and_dinner: mealTypes.includes(config.mealTypes.lunch) ? 'checked' : null,
                            supper: mealTypes.includes(config.mealTypes.supper) ? 'checked' : null,
                            recipe_category_id: recipe.recipe_category_id,
                            instructions: recipe.instructions,
                            extra_keywords: recipe.extra_keywords,
                            keywords: recipe.keywords,
                            notes: recipe.notes,
                            is_gluten_free: recipe.is_gluten_free,
                            is_lactose_free: recipe.is_lactose_free,
                            is_vegetarian_food: recipe.is_vegetarian_food,
                            is_pescatarian_food: recipe.is_pescatarian_food,
                            for_vegan_only: recipe.for_vegan_only,
                            is_user_public: recipe.is_user_public,
                            is_custom_energy: recipe.is_custom_energy,
                        }}
                        form={form}
                        autoComplete="off"
                        onFinish={onFinish}
                    >
                        <Divider orientation="left">
                            <Title style={{margin: 0}} level={4}>Retsepti info</Title>
                        </Divider>

                        {recipe.user_id && (
                            <div>
                                <Form.Item
                                    label="User ID"
                                    name="u_id">
                                    <Link to={"/user/" + recipe.user_id}>{recipe.user_id}</Link>
                                </Form.Item>

                                <Form.Item label="Is public" name="is_user_public" valuePropName="checked"><Checkbox
                                    value="1"></Checkbox></Form.Item>

                                <Divider/>
                            </div>
                        )}


                        <Form.Item
                            label="Retsepti nimi"
                            name="original_name"
                            rules={[{required: true, message: 'Palun sisesta retsepti avalik nimi'}]}>
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Slug"
                            name="slug"
                            rules={[{required: true, message: 'Palun sisesta retsepti slug'}]}>
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="SEO Title"
                            name="seo_title">
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="SEO Description"
                            name="seo_description">
                            <TextArea/>
                        </Form.Item>

                        <Form.Item label="Kategooria" name="recipe_category_id"
                                   rules={[{required: true, message: 'Palun vali retsepti kategooria'}]}>
                            <Select options={categories} value={recipe.recipe_category_id}></Select>
                        </Form.Item>

                        <Form.Item
                            label="Valmistamise aeg (min)"
                            name="preparation_time">
                            <InputNumber min={0} controls={true}/>
                        </Form.Item>

                        <Divider orientation="left">
                            <Title style={{margin: 0}} level={4}>Retsepti seaded</Title>
                        </Divider>

                        <Form.Item label="Fikseeritud kaloraaž" name="is_custom_energy"
                                   valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>

                        <Form.Item label="Lihtne" name="is_simple" valuePropName="checked"><Checkbox
                            value="1" onChange={handleIsSimpleChange}></Checkbox></Form.Item>

                        <Form.Item label="Aktiivne" name="is_active" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>
                        <Form.Item label="Avalik" name="is_public" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>
                        <Form.Item label="Esiletõstetud" name="is_featured" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>
                        <Form.Item label="Basic" name="is_basic" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>
                        <Form.Item label="Otse poest" name="is_from_store" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>
                        <Form.Item label="Valmistoit toidukohas" name="is_restaurant_meal"
                                   valuePropName="checked"><Checkbox value="1"></Checkbox></Form.Item>


                        <Divider orientation="left">
                            <Title style={{margin: 0}} level={4}>Toidukord</Title>
                        </Divider>

                        <Form.Item label="Hommikusöök" name="breakfast" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>
                        <Form.Item label="Lõunasöök / õhtusöök" name="lunch_and_dinner"
                                   valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>
                        <Form.Item label="Hiline õhtusöök" name="supper" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>

                        <Divider orientation="left">
                            <Title style={{margin: 0}} level={4}>Välistused</Title>
                        </Divider>

                        <Form.Item label="Gluteenivaba" name="is_gluten_free" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>

                        <Form.Item label="Laktoosivaba" name="is_lactose_free" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>

                        <Form.Item label="Taimetoit" name="is_vegetarian_food" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>

                        <Form.Item label="Pescatarian" name="is_pescatarian_food" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>

                        <Form.Item label="Vegan" name="for_vegan_only">
                            <Select options={forVeganOnlyOptions} value={recipe.for_vegan_only}></Select>
                        </Form.Item>

                        <Divider orientation="left">
                            <Title style={{margin: 0}} level={4}>Toiduained</Title>
                        </Divider>

                        <Form.Item wrapperCol={18} label="Toiduained" name="ingredients">
                            <Space direction="vertical" style={{width: "100%"}}>
                                <Space>
                                    <Alert message="Toiduained peaksid kokku olema 375 kcal." type="warning"/>
                                    <Alert message="Maitseaineteks loetakse toiduaineid, mille kogus on 0/NaN."
                                           type="warning"/>
                                </Space>

                                <IngredientList key={ingredients} setIngredients={setIngredients}
                                                ingredients={ingredients}/>
                            </Space>
                        </Form.Item>

                        <Form.Item label="Otsi toiduainet">
                            <IngredientSearch searchResponse={search} setSearchResponse={setSearch}
                                              onClickEvent={setIngredient} onlyId={false}/>
                        </Form.Item>

                        <Form.Item label="Kirjeldus" name="instructions">
                            <ReactQuill style={{minHeight: 100}} modules={modules} formats={formats}/>
                        </Form.Item>

                        <Divider orientation="left">
                            <Title style={{margin: 0}} level={4}>Lisad</Title>
                        </Divider>

                        <Form.Item label="Märkmed (nähtav vaid adminis)" name="notes">
                            <TextArea/>
                        </Form.Item>

                        <Form.Item label="Keywords(default + extra)" name="keywords">
                            <TextArea disabled="true"/>
                        </Form.Item>
                        <Form.Item label="Extra keywords" name="extra_keywords">
                            <TextArea/>
                        </Form.Item>


                        <Form.Item wrapperCol={{offset: 5, span: 16}}>
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    Salvesta
                                </Button>

                                <Popconfirm
                                    title={`Oled sa kindel, et soovid retsepti tõsta kõige uuemaks?`}
                                    onConfirm={() => forceToNewest()}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button icon={<StarOutlined/>}>Tõsta retsept esikohale</Button>
                                </Popconfirm>

                            </Space>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Content>
    );
}

export default RecipeView;